<template lang="pug">
.database
    v-card.ml-8(light)
        v-card-title.brand.white--text.py-2 Database Users ({{ users.length  }})
        v-card-text
            v-data-table(
                :headers="headers"
                :items="users"
                sort-by="group"
            )
                template(v-slot:top)
                    v-toolbar(flat)
                        v-spacer
                        v-dialog(
                            v-model='dialog'
                            max-width='900px'
                            light
                        )
                            template(v-slot:activator="{on, attrs}")
                                v-btn.brand.white--text(
                                    class="mb-2"
                                    v-bind="attrs"
                                    v-on="on"
                                ) New User
                            v-card
                                v-card-title.brand.white--text {{ formTitle }}
                                v-card-text
                                    v-container
                                        v-form( ref="form" v-model="valid" )
                                            v-row
                                                v-col(cols="12" sm="6" md="4")
                                                    v-text-field(
                                                        label="First Name"
                                                        v-model.trim="editedItem.nameFirst"
                                                        :rules="[rules.required]"
                                                        color="brand"
                                                    )
                                                v-col(cols="12" sm="6" md="4")
                                                    v-text-field(
                                                        label="Last Name"
                                                        v-model.trim="editedItem.nameLast"
                                                        :rules="[rules.required]"
                                                        color="brand"
                                                    )
                                                v-col(cols="12" sm="6" md="4" v-if="editedIndex === -1")
                                                    v-text-field(
                                                        label="Username"
                                                        v-model.trim="editedItem.username"
                                                        :rules="[rules.required, rules.minLength, rules.duplicate]"
                                                        color="brand"
                                                    )
                                                v-col(cols="12" sm="6" md="4")
                                                    v-text-field(
                                                        label="E-Mail"
                                                        v-model.trim="editedItem.email"
                                                        :rules="[rules.required, rules.email]"
                                                        color="brand"
                                                    )
                                                v-col(cols="12" sm="6" md="4")
                                                    v-select(
                                                        label="Group"
                                                        v-model.number="editedItem.groupId"
                                                        :items="groups"
                                                        item-text="name"
                                                        item-value="id"
                                                        :rules="[rules.required]"
                                                        color="brand"
                                                    )
                                v-card-actions
                                    v-spacer
                                    v-btn.actions.orange.white--text(
                                        text
                                        @click="close"
                                    ) Cancel
                                    v-btn.actions.brand.white--text(
                                        text
                                        :disabled="!valid"
                                        @click="save"
                                    ) Save
                        v-dialog(
                            v-model='dialogDelete'
                            max-width='500px'
                            light
                        )
                            v-card
                                v-card-title.brand.white--text Confirm Delete
                                v-card-text
                                    span.text-h6 Are you sure you want to delete this user?
                                v-card-actions
                                    v-spacer
                                    v-btn.orange.white--text(
                                        text
                                        @click="closeDelete"
                                    ) Cancel
                                    v-btn.brand.white--text(
                                        text
                                        @click="deleteItemConfirm"
                                    ) OK

                template(v-slot:item.actions="{ item }")
                    v-icon(
                        small
                        color="#333"
                        @click="editItem(item)"
                    ) edit
                    v-icon(
                        small
                        color="red"
                        @click="deleteItem(item)"
                    ).pl-2 delete
                template(v-slot:no-data)
                    v-btn(
                        color="primary"
                        @click="initialize"
                    ) Reset

</template>



<script lang="js">

export default {
    name: 'Database',
    data () {
        return {
            dialog: false,
            dialogDelete: false,
            headers: [
                { text: 'Last', value: 'nameLast', filterable: true},
                { text: 'First', value: 'nameFirst' },
                { text: 'Username', value: 'username' },
                { text: 'E-mail', value: 'email' },
                { text: 'Group', value: 'groupName' },
                { text: 'Actions', value: 'actions', sortable: false },
            ],
            editedIndex: -1,
            editedItem: {
                nameLast: '',
                nameFirst: '',
                username: '',
                email: '',
                groupName: '',
                groupId: 0,
            },
            defaultItem: {
                nameLast: '',
                nameFirst: '',
                username: '',
                email: '',
                groupName: '',
                groupId: 0,
            },
            users: [],
            dialogTitle: '',
            valid: false,
            rules: {
                required: value => !!value || 'Required.',
                email: value => /.+@.+\..+/.test(value) || 'E-mail must be valid',
                minLength: value => value && value.length >= 4 || 'Min 4 characters',
                duplicate: value => !this.checkUserExists(value) || 'Username already exists'
            }
        };
    },
    computed: {
        formTitle () {
            return this.editedIndex === -1 ? 'New User' : 'Edit User';
        },
        groups: {
            get () {
                const groups = [];
                const groupMap = new Map(this.users.map(({groupName, groupId}) => [groupName,groupId]));
                for(const [name,id] of groupMap) {
                    groups.push({name,id});
                }
                return groups;
            }
        }
    },
    watch: {
        dialog (val) {
            val || this.close();
        },
        dialogDelete (val) {
            val || this.closeDelete();
        },
    },
    created () {
        this.initialize();
    },
    methods: {
        initialize () {
            this.getUsers();
        },

        editItem (item) {
            this.editedIndex = this.users.indexOf(item);
            this.editedItem = Object.assign({}, item);
            this.dialog = true;
        },

        deleteItem (item) {
            this.editedIndex = this.users.indexOf(item);
            this.editedItem = Object.assign({}, item);
            this.dialogDelete = true;
        },

        deleteItemConfirm () {
            this.users.splice(this.editedIndex, 1);
            this.socketEmit('deleteDatabaseUser', this.editedItem, this.closeDelete);
        },

        close () {
            this.dialog = false;
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem);
                this.editedIndex = -1;
                this.valid = false;
            });
        },

        closeDelete () {
            this.dialogDelete = false;
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem);
                this.editedIndex = -1;
            });
        },

        save () {
            if (this.editedIndex > -1) {
                this.editedItem.groupName = this.groups.find(group => group.id === this.editedItem.groupId).name;
                Object.assign(this.users[this.editedIndex], this.editedItem);
            } else {
                this.users.push(this.editedItem);
            }
            this.socketEmit('saveDatabaseUser', this.editedItem, this.close);

        },

        /**
         * obtain the database user groups and users
         */
        getUsers () {
            this.socketEmit('getDatabaseUsers', null, (results) => {
                this.users = results || [];
            });
        },

        checkUserExists (username) {
            return Boolean(this.users.find(u => u.username.toLowerCase() === username.toLowerCase()));
        },

    }
};

</script>

<style lang="css">

.actions.v-btn--disabled.v-btn--text {
    background-color: #ddd !important;
}
.actions.v-btn--disabled > span {
    color: #777 !important;
    background-color: #ddd !important;
}

</style>
